import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './views/Home';
import Projects from './views/Projects';
import Services from './views/Services';
import About from './views/About';
import Contacts from './views/Contacts';
import OurStudio from './views/OurStudio';
import ProjectDetails from './views/ProjectDetails';
import AllTeams from './views/AllTeams';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/project/:slug" element={<ProjectDetails />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contacts />} />
          <Route path="/our-studio" element={<OurStudio />} />
          <Route path="/our-team" element={<AllTeams />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
