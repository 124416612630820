import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const Recaptcha = () => {
  const [isVerified, setIsVerified] = useState(false);

  const handleRecaptchaChange = (value) => {
    setIsVerified(true);
  };

  return (
    <div className='captcha'>
      <ReCAPTCHA
        sitekey="6LdjgLMpAAAAAIp9BvYzOpe-g3Lq0WkshBsIIAQr"
        onChange={handleRecaptchaChange}
        size='invisible'
      />
      {isVerified && (
        console.log('verified')
      )}
    </div>
  );
};

export default Recaptcha;
