import React from 'react';
import { Col, Row } from 'react-bootstrap';

const OurStudioImageStack = () => {
    return (
        <div style={{ width: '100%', flexDirection: 'column' }} className='flex-center'>
            <Row className="row-1920 image-stack" style={{ width: '100%' }}>
                <Col md={6} xs={12}>
                    <img src='/assets/images/stack1.png' alt='studio card' style={{ width: '100%' }} />
                    <img src='/assets/images/stack3.png' alt='studio card' style={{ width: '100%', aspectRatio: '900/510' }} />
                    <img src='/assets/images/stack5.png' alt='studio card' style={{ width: '100%' }} />
                </Col>
                <Col md={6} xs={12}>
                    <img src='/assets/images/stack2.png' alt='studio card' style={{ width: '100%' }} />
                    <img src='/assets/images/stack4.png' alt='studio card' style={{ width: '100%' }} />
                </Col>
            </Row>
            <Row className="row-1920 image-stack" style={{ width: '100%', padding: '15px 0' }}>
                <Col xs={12}>
                    <div className='note-txt flex-center' style={{ padding: '80px 20px', backgroundColor: '#EBEBEB' }}>We always love to create engaging content that is interesting and useful to children</div>
                </Col>
            </Row>
            <Row className="row-1920 image-stack" style={{ width: '100%' }}>
                <Col md={6} xs={12}>
                    <img src='/assets/images/stack6.png' alt='studio card' style={{ width: '100%' }} />
                    <img src='/assets/images/stack7.png' alt='studio card' style={{ width: '100%', aspectRatio: '900/510' }} />
                    <img src='/assets/images/stack8.png' alt='studio card' style={{ width: '100%' }} />
                </Col>
                <Col md={6} xs={12}>
                    <img src='/assets/images/stack9.png' alt='studio card' style={{ width: '100%' }} />
                    <img src='/assets/images/stack10.png' alt='studio card' style={{ width: '100%' }} />
                </Col>
            </Row>
        </div>
    );
};

export default OurStudioImageStack;