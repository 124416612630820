import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { locations } from '../data/data';

const LocationCard = () => {
    return (
        <Row className="justify-content-center">
            <Col md={10} xs={12}>
                <Row className="justify-content-center" style={{ marginTop: 30 }}>
                    {
                        locations.map((item, index) => (
                            <Col md={4} xs={12} key={index}>
                                <div className='full-width location-card' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div className='full-width' style={{ border: '10px solid #E6E6E6', marginBottom: 20 }}>
                                        <iframe title={item.name} src={item.geo} style={{ border: 0, width: '100%', aspectRatio: '4/3' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                    <p style={{ fontSize: 24, color: '#FF0101', fontWeight: 700 }}>{item.name}</p>
                                    <p className='montserrat-regular note-txt' style={{ textAlign: 'center' }}>{item.address}</p>
                                </div>
                            </Col>
                        ))
                    }
                </Row>
            </Col>
        </Row>
    );
};

export default LocationCard;