import React, { useState, useEffect } from 'react';
import { PlayCircleFill } from 'react-bootstrap-icons';

const ProjectCard = ({ data, index }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    useEffect(() => {
        const video = document.getElementById(`system-video-${index}`);
        if (video) {
            if (isHovered) {
                video.play();
            } else {
                video.pause();
                video.currentTime = 0;
            }
        }
    }, [isHovered, index]);

    return (
        <a href={`/project/${data.url}`}>
            <div className='project-card'>
                <div className='video-container'>
                    <video id={`system-video-${index}`} className="project-video" loop muted playsInline autoPlay={isHovered}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <source src={data.video} type='video/mp4' />
                    </video>
                    {
                        data.thumb && !isHovered && <div className="overlay" style={{ backgroundImage: `url(${data.thumb})`, backgroundSize: 'cover' }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        </div>
                    }

                </div>
                <a href={`/project/${data.url}`} className='card-project' >
                    <p className='card-title max-two-lines' title={data.title} style={{ textTransform: 'uppercase', flex: 1 }} dangerouslySetInnerHTML={{
                        __html: data.title,
                    }}></p>
                    <PlayCircleFill className='play-icon-project' />
                </a>
            </div>
        </a>
    );
};

export default ProjectCard;