import React from 'react';
import { Col, Row } from 'react-bootstrap';


const Quotes = () => {
    return (
        <div style={{ backgroundColor: '#EBEBEB', padding: 60 }}>
            <Row>
                <Col style={{ textAlign: 'center' }} className='quote-2'>
                    <p className='poppins-light quotes'>We believes that the human<br /> is the most important factor with the company.</p>
                    <p className='montserrat quote-sub1' style={{ color: '#FF0101' }}>LINH BUI</p>
                    <p className='montserrat-regular quote-sub2' >CO FOUNDER ZIC ZIC</p>
                </Col>
            </Row>
        </div>
    );
};

export default Quotes;