import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { yt_content, yt_marketing, yt_net_work_content, yt_seo, yt_social_media } from '../data/data';

const ListItems = () => {
    return (
        <div className='list-items'>
            <Row style={{ flexDirection: 'row-reverse' }}>
                <Col xl={6} xs={12}>
                    <div className='right-img'>
                        <div className='red-background'>
                            <img src="/assets/images/yt-network.png" alt="youtube network" style={{ width: '100%' }} />
                        </div>
                    </div>
                </Col>
                <Col xl={6} xs={12}>
                    <p className='title-txt'>YOUTUBE<br /> NETWORK</p>
                    <p className='note-txt' dangerouslySetInnerHTML={{
                        __html: yt_net_work_content,
                    }}></p>
                </Col>
            </Row >
            <Row>
                <Col xl={6} xs={12}>
                    <div className='left-img'>
                        <div className='red-background'>
                            <img src="/assets/images/youtube-seo.png" alt="youtube network" style={{ width: '100%' }} />
                        </div>
                    </div>
                </Col>
                <Col xl={6} xs={12}>
                    <p className='title-txt'>YOUTUBE<br /> SEO</p>
                    <p className='note-txt' dangerouslySetInnerHTML={{
                        __html: yt_seo,
                    }}>
                    </p>
                </Col>
            </Row>
            <Row style={{ flexDirection: 'row-reverse' }}>
                <Col xl={6} xs={12}>
                    <div className='right-img'>
                        <div className='red-background'>
                            <img src="/assets/images/social-media.png" alt="youtube network" style={{ width: '100%' }} />
                        </div>
                    </div>
                </Col>
                <Col xl={6} xs={12}>
                    <p className='title-txt'>SOCIAL MEDIA<br /> MANAGEMENT</p>
                    <p className='note-txt' dangerouslySetInnerHTML={{
                        __html: yt_social_media,
                    }}></p>
                </Col>
            </Row >
            <Row>
                <Col xl={6} xs={12}>
                    <div className='left-img'>
                        <div className='red-background'>
                            <img src="/assets/images/marketing.png" alt="youtube network" style={{ width: '100%' }} />
                        </div>
                    </div>
                </Col>
                <Col xl={6} xs={12}>
                    <p className='title-txt'>INFLUENCER <br />MARKETING</p>
                    <p className='note-txt' dangerouslySetInnerHTML={{
                        __html: yt_marketing,
                    }}></p>
                </Col>
            </Row>
            <Row style={{ flexDirection: 'row-reverse' }}>
                <Col xl={6} xs={12}>
                    <div className='right-img'>
                        <div className='red-background'>
                            <img src="/assets/images/content-distribution.png" alt="youtube network" style={{ width: '100%' }} />
                        </div>
                    </div>
                </Col>
                <Col xl={6} xs={12}>
                    <p className='title-txt'>CONTENT <br />DISTRIBUTION</p>
                    <p className='note-txt' dangerouslySetInnerHTML={{
                        __html: yt_content,
                    }}></p>
                </Col>
            </Row >
        </div>
    );
};

export default ListItems;