import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Timeline } from '../data/data';

const LandMark = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 767);

    useEffect(() => {
        function handleResize() {
            setIsSmallScreen(window.innerWidth < 767);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div>
            <Row>
                <Col className='flex-center' style={{ textAlign: 'center' }}>
                    <p className='title-txt'>EVENT LANDMARK</p>
                </Col>
            </Row>
            <div className="timeline">
                {
                    Timeline.map((item, index) => (
                        <div className={`timeline-container ${(index % 2 === 0 && !isSmallScreen) ? 'left' : 'right'}`} key={index}>
                            <div className="content">
                                <h2 className='montserrat-regular' style={{ fontSize: 18, color: '#ee0909' }}>{item.date}</h2>
                                <p dangerouslySetInnerHTML={{
                                    __html: item.title,
                                }}></p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default LandMark;