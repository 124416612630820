import React from 'react';
import CountUp from 'react-countup';
import { Col, Row } from 'react-bootstrap';
import VisibilitySensor from 'react-visibility-sensor';
import { ANALYTICS, MONETIZATION, our_channel_title, right_management } from '../data/data';

const OurChannel = () => {
    const [focus, setFocus] = React.useState(false);
    return (
        <div className='flex-center' style={{ flexDirection: 'column' }}>
            <div style={{ width: '100%', backgroundColor: '#fff', flexDirection: 'column' }} className='flex-center'>
                <Row>
                    <Col style={{ textAlign: 'center', paddingTop: 60 }}>
                        <p className='title-txt'>OUR CHANNELS</p>
                        <p className='note-txt'>{our_channel_title}</p>
                    </Col>
                </Row>
                <Row className="row-1920" style={{ width: '100%' }}>
                    <Col xs={6} md={3} className='our-channel-sub-title' style={{ paddingTop: 0 }}>
                        <div>
                            <p className='title-txt' style={{ marginBottom: '-5px' }}>
                                <CountUp start={focus ? 0 : null} end={35} >
                                    {({ countUpRef }) => (
                                        <VisibilitySensor onChange={(isVisible) => {
                                            if (isVisible) { setFocus(true); }
                                        }} delayedCall active>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                                M+
                            </p>
                            <p>Subscribers</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className='our-channel-sub-title' style={{ paddingTop: 0 }}>
                        <div>
                            <p className='title-txt' style={{ marginBottom: '-5px' }}>
                                <CountUp start={focus ? 0 : null} end={50} >
                                    {({ countUpRef }) => (
                                        <VisibilitySensor onChange={(isVisible) => {
                                            if (isVisible) { setFocus(true); }
                                        }} delayedCall active>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                                +
                            </p>
                            <p>Channels</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className='our-channel-sub-title' style={{ paddingTop: 0 }}>
                        <div>
                            <p className='title-txt' style={{ marginBottom: '-5px' }}>
                                <CountUp start={focus ? 200 : null} end={300} >
                                    {({ countUpRef }) => (
                                        <VisibilitySensor onChange={(isVisible) => {
                                            if (isVisible) { setFocus(true); }
                                        }} delayedCall active>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                                M+</p>
                            <p>Monthly Views</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className='our-channel-sub-title' style={{ paddingTop: 0 }}>
                        <div>
                            <p className='title-txt' style={{ marginBottom: '-5px' }}>
                                <CountUp start={focus ? 7000 : null} end={8000} duration={1.5}>
                                    {({ countUpRef }) => (
                                        <VisibilitySensor onChange={(isVisible) => {
                                            if (isVisible) { setFocus(true); }
                                        }} delayedCall active>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                                +</p>
                            <p>Total Contents</p>
                        </div>
                    </Col>
                </Row>
            </div>
            <div style={{ width: '100%', backgroundColor: '#f1f1f1', flexDirection: 'column' }} className='flex-center zc-what-do-dit'>
                <Row>
                    <Col className='our-channels' style={{ textAlign: 'center', paddingBottom: 0 }}>
                        <p className='title-txt'>WHAT CAN ZIC ZIC <br /> DO FOR YOU?</p>
                    </Col>
                </Row>
                <Row className='row-1920'>
                    <Col xs={12} md={4} style={{ paddingTop: 0 }}>
                        <div className='content'>
                            <img src='/assets/images/Security_Portal.png' alt='Security Portal' />
                            <p style={{ fontSize: 24, color: '#FF0101' }}>Rights Management</p>
                            <p className='montserrat-regular note-txt' style={{ textAlign: 'center' }}>{right_management}</p>
                        </div>
                    </Col>
                    <Col xs={12} md={4} style={{ paddingTop: 0 }}>
                        <div className='content'>
                            <img src='/assets/images/Graph_Report_Script.png' alt='Security Portal' />
                            <p style={{ fontSize: 24, color: '#FF0101' }}>ANALYTICS</p>
                            <p className='montserrat-regular note-txt' style={{ textAlign: 'center' }}>{ANALYTICS}</p>
                        </div>
                    </Col>
                    <Col xs={12} md={4} style={{ paddingTop: 0 }}>
                        <div className='content'>
                            <img src='/assets/images/Total_Sales.png' alt='Security Portal' />
                            <p style={{ fontSize: 24, color: '#FF0101' }}>MONETIZATION</p>
                            <p className='montserrat-regular note-txt' style={{ textAlign: 'center' }}>{MONETIZATION}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                        </div>
                    </Col>
                </Row>
            </div>
            <Row className='row-1920'>
                <Col md={6} xs={12}>
                    <div style={{ display: 'flex', padding: 20 }}>
                        <img src='/assets/images/studio.png' alt='Security Portal' style={{ width: '100%', aspectRatio: '16/9', objectFit: 'cover', borderRadius: 10 }} />
                    </div>
                </Col>
                <Col md={6} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='partner-ship' style={{ width: '100%', textAlign: 'left', padding: 0 }}>
                        <p className='montserrat text-md-start text-center'>OUR STUDIO</p>
                        <p className='montserrat-regular text-md-start text-center note-txt' style={{
                            fontWeight: 100,
                            marginTop: -10, padding: 10
                        }}>We help you identify the specific metrics to track in order to improve various aspects of your video business. Get actionable advice on analytics to help you improve your content strategy and understand trends that affect your overall channel health</p>
                        <div style={{
                            width: '100%',
                            textAlign: 'left'
                        }} className='montserrat text-md-start text-center'>
                            <a href='/our-studio' className='button-banner'>View our studio</a>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default OurChannel;