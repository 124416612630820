import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Lottie from "lottie-react";
import zAnimation from "./Z.json";

const Banner = () => {
    const [isSticky, setSticky] = useState(false);
    const [videoPlayed, setVideoPlayed] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", () => handleScroll);
        };
    }, []);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };

    const handleVideoPlay = () => {
        setVideoPlayed(true);
    };

    return (
        <Row>
            <Col xs={12} style={{ padding: 0 }}>
                <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', position: 'relative' }}>
                    <video className='system-video banner-video' autoplay="autoplay" muted="muted" loop="loop" playsInline preload="auto" onPlay={handleVideoPlay}>
                        <source src="/assets/video/web_11.mp4" type='video/mp4' />
                    </video>
                    {!videoPlayed && (
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: '#2e2c2f'
                            }}
                        />
                    )}
                    <div className='sharp-wrapper'>
                    </div>
                    {!videoPlayed && <div className='z-wrapper'>
                        <Lottie className='z-icon' animationData={zAnimation} />
                    </div>}

                    {
                        !isSticky && <div className="mouse_scroll">
                            <div className="mouse">
                                <div className="wheel"></div>
                            </div>
                            <div>
                                <span className="m_scroll_arrows unu"></span>
                                <span className="m_scroll_arrows doi"></span>
                                <span className="m_scroll_arrows trei"></span>
                            </div>
                        </div>
                    }
                </div>
            </Col>
        </Row>
    );
};

export default Banner;