import React from 'react';
import { Container } from 'react-bootstrap';
import Footer from '../components/Footer';
import HeaderPage from '../components/HeaderPage';
import { Col, Row } from 'react-bootstrap';
import { SlideItem } from '../components/SlideItem';
import { OurTeams } from '../data/data';
import { Helmet } from 'react-helmet';
import { titlePage } from '../data/data';

const AllTeams = () => {

    return (
        <div>
            <Container fluid className='p-0' style={{ overflow: 'hidden' }}>
                <Helmet>
                    <title>{titlePage.ourteam}</title>
                </Helmet>
                <HeaderPage />
                <div className='flex-center full-width' style={{ padding: 30 }}>
                    <Row className='row-1500'>
                        <Col style={{ textAlign: 'center', paddingTop: 60 }}>
                            <p className='title-txt'>Our team</p>
                        </Col>
                    </Row>
                </div>
                <div className='flex-center full-width' style={{ padding: '0 30px' }}>
                    <Row className='row-1500'>
                        <Col className='grid-teams'>
                            {
                                OurTeams.teams.map((item, index) => (
                                    <div key={index} className='profile-grid'>
                                        <SlideItem data={item} />
                                    </div>
                                ))
                            }
                        </Col>
                    </Row>
                </div>
                <br />
                <br />
                <br />
                <Footer />
            </Container>
        </div>
    );
};

export default AllTeams;