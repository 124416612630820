import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Header = () => {
    const [isSticky, setSticky] = useState(false);

    const location = useLocation();
    const currentPath = location.pathname;

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", () => handleScroll);
        };
    }, []);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    return (
        <header className={isSticky ? "header sticky" : "header"}>
            <div className="logo">
                <a href="/"><img src='/assets/images/logo.png' alt="logo" /></a>
            </div>
            <nav className="menu montserrat">
                <ul className='menu-pc'>

                    <li><a className={currentPath === '/' ? 'active' : ''} href="/">HOME</a></li>
                    <li><a className={currentPath === '/project' ? 'active' : ''} href="/projects">PROJECT</a></li>
                    <li><a className={currentPath === '/service' ? 'active' : ''} href="/services">SERVICE</a></li>
                    <li><a className={currentPath === '/about' ? 'active' : ''} href="/about">ABOUT</a></li>
                    <li><a className={currentPath === '/contact' ? 'active' : ''} href="/contact">CONTACT</a></li>
                    {/* <li><a href="/">HOME</a></li>
                    <li><a href="/projects">PROJECT</a></li>
                    <li><a href="/services">SERVICE</a></li>
                    <li><a href="/about">ABOUT</a></li>
                    <li><a href="/contact">CONTACT</a></li> */}
                </ul>
                <div className={`navbar-links ${isOpen ? 'active' : ''}`}>
                    <ul>
                        <li><a href="/">HOME</a></li>
                        <li><a href="/projects">PROJECT</a></li>
                        <li><a href="/services">SERVICE</a></li>
                        <li><a href="/about">ABOUT</a></li>
                        <li><a href="/contact">CONTACT</a></li>
                    </ul>
                </div>
                <div className={isOpen ? "menu-button1 change" : "menu-button"} onClick={toggleMenu}>
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                </div>
            </nav>
        </header>
    );
};

export default Header;