import React from 'react';
import { Col, Row } from 'react-bootstrap';

const OurStudioCard = () => {
    return (
        <div>
            <Row>
                <Col className='flex-center' style={{ textAlign: 'center' }}>
                    <p className='title-txt'>OUR STUDIO</p>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col className='flex-center' style={{ textAlign: 'center' }}>
                    <p className='note-txt'> Visit where we've made interesting videos and meet creative young people. </p>
                </Col>
            </Row>
        </div>
    );
};

export default OurStudioCard;