import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { DataParters } from '../data/data';


const Partners = () => {
    return (
        <Row className="justify-content-center">
            <Row className="justify-content-center">
                <Col xs={12} className='flex-center our-partner'>
                    <p className='title-txt'>OUR PARTNER</p>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xxl={12} xl={12} md={12} xs={12} className="flex-center">
                    <div className='max-width-1550 flex-space-between full-width div-scroll-width'>
                        {
                            DataParters.map((item, index) => (
                                <img className="parter-img" key={index} src={item.image} alt={item.title} />
                            ))
                        }
                    </div>
                    {/* <TestSlide /> */}
                    {/* <Slider {...settings}>
                            {
                                DataParters.map((item, index) => (
                                    <div key={index} style={{ padding: 20, display: 'flex !important' }} className='flex-center'>
                                        <img src={item.image} alt={item.name} />
                                    </div>
                                ))
                            }
                        </Slider> */}
                </Col>
            </Row>
        </Row>
    );
};

export default Partners;