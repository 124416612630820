import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ProjectCard from './ProjectCard';
import { ProjectDB } from '../data/data';

const AllProject = ({ tags }) => {
    return (
        <>
            <Row>
                <Col xs={12}>
                    <div className='project-category'>
                        <button className='button-zc-2 active'>All project</button>
                        {tags.map((item, index) => (
                            <button className='button-zc-2' key={index}>{item}</button>
                        ))}
                    </div>
                </Col>
            </Row>
            <Row className='project-list'>
                {
                    ProjectDB.projects.sort((a, b) => b.id - a.id).map((item, index) => (
                        <Col xxl={4} xl={6} md={6} sm={6} xs={12} key={index} className='no-padding'>
                            <ProjectCard data={item} index={index} />
                        </Col>
                    ))
                }
            </Row>
        </>
    );
};

export default AllProject;