import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Slider from "react-slick";
import { SlideItem } from './SlideItem';
import { OurTeams } from '../data/data';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
                dots: false
            }
        }
    ]
};


const OurTeam = () => {
    return (
        <div style={{ backgroundColor: '#EBEBEB', padding: 60 }}>
            <Row>
                <Col className='flex-center' style={{ textAlign: 'center' }}>
                    <p className='title-txt'>OUR TEAM</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Slider {...settings}>
                        {
                            OurTeams.teams.map((item, index) => (
                                <div key={index}>
                                    <SlideItem data={item} />
                                </div>
                            ))
                        }
                    </Slider>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='flex-center' style={{ marginTop: 60 }}>
                        <a href='/our-team' className='button-banner large-btn' style={{ marginTop: 20 }}>See all our team</a>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default OurTeam;