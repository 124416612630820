import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Facebook, Tiktok, Youtube } from 'react-bootstrap-icons';
import { locations } from '../data/data';

const Footer = () => {
    return (
        <Row style={{ backgroundColor: '#D9D9D9' }}>
            <Col xs={12}>
                <div className='zc-footer'>
                    <img src='/assets/images/logo.png' alt="logo" /><span className='montserrat title-txt'>ZIC ZIC</span>
                </div>
            </Col>
            {
                locations.map((item, index) => (
                    <Col md={4} xs={12} key={index}>
                        <div className='addr'>
                            <p>{item.name}</p>
                            <p className='montserrat-regular note-txt' style={{ textAlign: 'center' }}>{item.address}</p>
                            <p className='montserrat-regular note-txt' style={{ textAlign: 'center', marginTop: -10 }}>{item.email}</p>
                            <p className='montserrat-regular note-txt' style={{ textAlign: 'center', marginTop: -10 }}>{item.phone}</p>
                        </div>
                    </Col>
                ))
            }
            <Row>
                <Col xs={12}>
                    <div className='social'>
                        <a href="https://www.facebook.com/ZicZicNetwork/" target="_blank" rel="noopener noreferrer" ><div className='icon-fb'><Facebook /></div> </a>
                        <a href="https://www.tiktok.com/@zicziccorp" target="_blank" rel="noopener noreferrer"><div className='icon' style={{ margin: '0 20px' }} ><Tiktok /></div></a>
                        <a href="https://www.youtube.com/@ZicZicStudio" target="_blank" rel="noopener noreferrer"><div className='icon'><Youtube /></div></a>
                    </div>
                </Col>
            </Row>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', backgroundColor: '#FF0101' }}>
                <p className='montserrat-regular' style={{ fontSize: 16, textAlign: 'center', color: '#fff', marginTop: '1rem' }}>Copyright © 2016 by ZicZic Network</p>
            </div>

        </Row >
    );
};

export default Footer;