import React from 'react';

export function SlideItem({ data }) {
    return (<div className='flex-center' style={{ flexDirection: 'column' }}>
        <img src={`/assets/images/${data.image}`} alt='Projects' className='profile-image' />
        <br />
        <p className='sub-title-2' style={{ marginBottom: 0 }}>{data.name}</p>
        <p className='note-txt'>{data.role}</p>
    </div>);
}
