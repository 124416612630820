import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Footer from '../components/Footer';
import HeaderPage from '../components/HeaderPage';
import { ProjectDB, titlePage } from '../data/data';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Col, Row } from 'react-bootstrap';
import ProjectSlide from '../components/ProjectSlide';
import { Helmet } from 'react-helmet';
import Lottie from "lottie-react";
import zAnimation from "../components/Z.json";

const ProjectDetails = () => {
    const navigate = useNavigate();
    const { slug } = useParams();
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = () => {
            const dataTmp = ProjectDB.projects.find(project => project.url === slug);
            if (!dataTmp) {
                return navigate("/");
            } else {
                setData(dataTmp)
            }
        }
        fetchData();
    }, [navigate, slug])

    const [videoPlayed, setVideoPlayed] = useState(false);
    const handleVideoPlay = () => {
        setVideoPlayed(true);
    };

    return (
        <>
            {data &&
                <Container fluid className='p-0' style={{ overflow: 'hidden' }}>
                    <Helmet>
                        <title>{data.title + ' | ' + titlePage.home}</title>
                    </Helmet>
                    <HeaderPage />
                    <div className='flex-center full-width' style={{ padding: '0 10px' }}>
                        <Row className='row-1500'>
                            <Col style={{ textAlign: 'center', paddingTop: 40 }}>
                                <p className='title-txt pc-font-40' dangerouslySetInnerHTML={{
                                    __html: data.title,
                                }}></p>
                            </Col>
                        </Row>
                    </div>
                    <div className='flex-center full-width project-detail' style={{ padding: '0 10px' }}>
                        <Row className='row-1500 full-width'>
                            <Col xs={12} className='flex-center'>
                                <div className='video-detail'>
                                    <video className='system-video' autoPlay loop muted playsInline onPlay={handleVideoPlay}>
                                        <source src={data.video} type='video/mp4' />
                                    </video>
                                    {!videoPlayed && (
                                        <div
                                            className='background-video'
                                        />
                                    )}
                                    {!videoPlayed && <div className='z-wrapper'>
                                        <Lottie className='z-icon' animationData={zAnimation} />
                                    </div>}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='flex-center full-width' style={{ padding: 10 }}>
                        <Row className='row-1500'>
                            <Col style={{ textAlign: 'center', paddingTop: 30 }}>
                                <p className='sub-title-2' style={{ color: '#ff0101' }}>PPROJECT INFORMATION</p>
                            </Col>
                        </Row>
                    </div>
                    <div className='flex-center full-width' style={{ padding: 20, paddingTop: 0 }}>
                        <Row className='row-1500'>
                            <Col style={{ textAlign: 'justify' }}>
                                <p className='note-txt' dangerouslySetInnerHTML={{
                                    __html: data.information,
                                }}></p>
                            </Col>
                        </Row>
                    </div>
                    <div className='flex-center full-width' style={{ padding: 10, paddingBottom: 0 }}>
                        <Row className='row-1500'>
                            <Col style={{ textAlign: 'center' }}>
                                <p className='title-txt' >RELATED VIDEOS</p>
                            </Col>
                        </Row>
                    </div>
                    <div className='flex-center full-width' style={{ padding: 10 }}>
                        <ProjectSlide data={slug} />
                    </div>
                    <Footer />
                </Container>
            }
        </>
    );
};

export default ProjectDetails;