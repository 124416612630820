import React from 'react';
import { Container } from 'react-bootstrap';
import Footer from '../components/Footer';
import ServicesEnd from '../components/ServicesEnd';
import { Col, Row } from 'react-bootstrap';
import HeaderPage from '../components/HeaderPage';
import LocationCard from '../components/LocationCard';
import { Helmet } from 'react-helmet';
import { contact_title, titlePage } from '../data/data';
import Recaptcha from '../components/Recaptcha';

const Contacts = () => {
    return (
        <div style={{ position: 'relative' }}>
            <Container fluid className='p-0' style={{ overflow: 'hidden' }}>
                <Helmet>
                    <title>{titlePage.contact}</title>
                </Helmet>
                <HeaderPage />
                <Row>
                    <Col className='our-channels' style={{ backgroundColor: '#fff', textAlign: 'center', paddingBottom: 0 }}>
                        <p>CONTACT</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='flex-center' style={{ backgroundColor: '#fff', textAlign: 'center' }}>
                        <p className='note-txt'>{contact_title}
                        </p>
                    </Col>
                </Row>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '60px 10px' }}>
                    <ServicesEnd />
                </div>
                <Row>
                    <Col className='flex-center' style={{ position: 'relative', paddingTop: 20, color: '#fff' }}>
                        <div className='head-overlay'></div>
                        <p className='title-txt black-mobile' style={{ marginBottom: 0 }}>OUR HEADQUARTER</p>
                    </Col>
                </Row>
                <LocationCard />
                <Footer />
            </Container>
            <Recaptcha />
        </div>
    );
};

export default Contacts;