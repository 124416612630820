export const ProjectDB = {
    title: 'OUR PROJECT',
    subTitle: 'Let\'s explore the most outstanding products created by the talented team at Zic Zic.',
    image: '/assets/images/project-banner.png',
    project_tags: [
        '#Character', '#2Danimation', '#3Danimation', '#Handmade', '#Cooking', '#FrameByFrame'
    ],
    projects: [
        {
            id: 1,
            title: "CHARACTER MOTHER DUCK - 3D ANIMATION PROJECT",
            video: '/assets/video/acd811c-mother-duck.mp4',
            url: 'character-mother-duck-3d-animation-project',
            pri: 0,
            information: 'At ZicZic, we have the passion to create useful stories for children. The products are manufactured according to the process set out. We convince viewers with beautiful images and live sound.<br/>One of the animateds produced for a client. The brief was to create and animate, a cute cartoon style, flying in and interacting/announcing each text element throughout. The final animations mentioned a particular trait or quality, and each one was sent to particular employees through the company intranet.<br/>The final result was very well received, both by the children and health care professionals it directly benefitted, and along with its surrounding media package elements.'
            , thumb: '/assets/video/acd811c-mother-duck.jpg'
        },
        {
            id: 2,
            title: "CHARACTER DADDY - 3D ANIMATION PROJECT",
            video: '/assets/video/acd3f82-model-1.mp4',
            url: 'beautiful-house-on-the-meadow-environment',
            pri: 0,
            information: 'At ZicZic, we have the passion to create useful stories for children. The products are manufactured according to the process set out. We convince viewers with beautiful images and live sound.<br/>One of the animateds produced for a client. The brief was to create and animate, a cute cartoon style, flying in and interacting/announcing each text element throughout. The final animations mentioned a particular trait or quality, and each one was sent to particular employees through the company intranet.<br/>The final result was very well received, both by the children and health care professionals it directly benefitted, and along with its surrounding media package elements.'
            , thumb: '/assets/video/acd3f82-model-1.jpg'
        },
        {
            id: 3,
            title: "BEAUTIFUL HOUSE ON THE MEADOW - ENVIRONMENT",
            video: '/assets/video/acde8f1-enviroment.mp4',
            url: 'beautiful-house-on-the-meadow-environment',
            pri: 0,
            information: 'At ZicZic, we have the passion to create useful stories for children. The products are manufactured according to the process set out. We convince viewers with beautiful images and live sound.<br/>One of the animateds produced for a client. The brief was to create and animate, a cute cartoon style, flying in and interacting/announcing each text element throughout. The final animations mentioned a particular trait or quality, and each one was sent to particular employees through the company intranet.<br/>The final result was very well received, both by the children and health care professionals it directly benefitted, and along with its surrounding media package elements.'
            , thumb: '/assets/video/acde8f1-enviroment.jpg'
        },
        {
            id: 6,
            title: "SPLITTING HARES - PENCILANIMATION PROJECT",
            video: '/assets/video/ziczic-hares.mp4',
            url: 'splitting-hares-pencilanimation-project',
            pri: 3,
            information: 'At ZicZic, we have the passion to create useful stories for children. The products are manufactured according to the process set out. We convince viewers with beautiful images and live sound.<br/>One of the animateds produced for a client. The brief was to create and animate, a cute cartoon style, flying in and interacting/announcing each text element throughout. The final animations mentioned a particular trait or quality, and each one was sent to particular employees through the company intranet.<br/>The final result was very well received, both by the children and health care professionals it directly benefitted, and along with its surrounding media package elements.'
            , thumb: '/assets/video/ziczic-hares.jpg'
        },
        {
            id: 7,
            title: "THE TINY HOUSE UNDER THE MOONLIGHT - 3D ANIMATION PROJECT",
            video: '/assets/video/acde223-1.mp4',
            url: 'the-tiny-house-under-the-moonlight-3d-animation-project',
            pri: 2,
            information: 'At ZicZic, we have the passion to create useful stories for children. The products are manufactured according to the process set out. We convince viewers with beautiful images and live sound.<br/>One of the animateds produced for a client. The brief was to create and animate, a cute cartoon style, flying in and interacting/announcing each text element throughout. The final animations mentioned a particular trait or quality, and each one was sent to particular employees through the company intranet.<br/>The final result was very well received, both by the children and health care professionals it directly benefitted, and along with its surrounding media package elements.'
            , thumb: '/assets/video/acde223-1.jpg'
        },
        {
            id: 8,
            title: "3D ANIMATION PROJECT",
            video: '/assets/video/acdccb4-demo3-fiveduck.mp4',
            url: '3d-animation-project',
            pri: 0,
            information: 'At ZicZic, we have the passion to create useful stories for children. The products are manufactured according to the process set out. We convince viewers with beautiful images and live sound.<br/>One of the animateds produced for a client. The brief was to create and animate, a cute cartoon style, flying in and interacting/announcing each text element throughout. The final animations mentioned a particular trait or quality, and each one was sent to particular employees through the company intranet.<br/>The final result was very well received, both by the children and health care professionals it directly benefitted, and along with its surrounding media package elements.'
            , thumb: '/assets/video/acdccb4-demo3-fiveduck.jpg'
        },
        {
            id: 9,
            title: "TEN LITTLE DUCKIES - 3D ANIMATION PROJECT",
            video: '/assets/video/acdda38-ten-little-duck.mp4',
            url: 'ten-little-duckies-3d-animation-project',
            pri: 1,
            information: 'At ZicZic, we have the passion to create useful stories for children. The products are manufactured according to the process set out. We convince viewers with beautiful images and live sound.<br/>One of the animateds produced for a client. The brief was to create and animate, a cute cartoon style, flying in and interacting/announcing each text element throughout. The final animations mentioned a particular trait or quality, and each one was sent to particular employees through the company intranet.<br/>The final result was very well received, both by the children and health care professionals it directly benefitted, and along with its surrounding media package elements.'
            , thumb: '/assets/video/acdda38-ten-little-duck.jpg'
        },
        {
            id: 10,
            title: "ASMR Video From Slime",
            video: '/assets/video/slime.mp4',
            url: 'slime-project',
            pri: 0,
            information: 'ZicZic, a solid name in both entertainment and education for children, takes pride in providing creative and beneficial experiences. And in this journey of exploration, we cannot overlook the fun of playing with slime - an activity full of color and allure.<br/>Slime is not just a stretchy product; it\'s also an opportunity for endless exploration for children. From crafting unique shapes, even creating interesting sounds, to engaging in creative challenges, we provide a safe and stimulating space for children\'s creativity to flourish.<br/>Playing with slime not only brings about joy and relaxation but also encourages comprehensive development, from cognitive skills to social interaction abilities. Come to ZicZic, where children are encouraged to explore, learn, and grow every day through our creative videos.'
            , thumb: '/assets/video/slime.jpg'
        },
        {
            id: 11,
            title: "A series of educational stories featuring toy characters",
            video: '/assets/video/alice.mp4',
            url: 'toys-project',
            pri: 0,
            information: 'At ZicZic, animating character models like Cocomelon, Peppa Pig, or Bluey is a vibrant and creative process. Animation involves not only capturing the unique personalities, gestures, and tones of each character but also creating lively and relatable experiences for our young audience. <br/> <br/>We must ensure that every situation, every conversation is portrayed vividly and engagingly, helping children understand and absorb content in the most natural and enjoyable way possible. Additionally, flexibility and expressiveness are essential factors in adapting the narrative to suit different situations and age groups of the audience. <br/> <br/>In addition to animation, we also focus on content development, welcoming feedback to enrich and diversify. With passion and creativity, we constantly seek ways to innovate and enhance the quality of our products, aiming to bring joy and happiness to millions of children worldwide.'
            , thumb: '/assets/images/thumbs/cocomelon.jpeg'
        },
        {
            id: 11,
            title: "Satisfying video from Kinetic Sand",
            video: '/assets/video/sand.mp4',
            url: 'sand-project',
            pri: 0,
            information: 'For ZicZic, we recognize that kinetic sand is not just a simple material but also an unlimited creative world for children. <br/> <br/>By using kinetic sand as a creative crafting medium, children can explore and develop their artistic talents freely and joyfully. Building sandcastles, sculpting animals using available molds not only helps children develop creative thinking and imagination but also enhances spatial reasoning and logical thinking skills. <br/> <br/>Moreover, playing with kinetic sand also helps children cultivate patience and concentration to create colorful structures. Therefore, we create videos featuring various animals, objects, and vibrant colors to stimulate children\'s curiosity, encouraging them to explore and learn new things.'
            , thumb: '/assets/images/thumbs/kineticsand.jpeg'
        },
        {
            id: 12,
            title: "Build house, toy models from magnetic balls",
            video: '/assets/video/vid_bi.mp4',
            url: 'magnet-ball-project',
            pri: 0,
            information: 'Educating and fostering creativity through magnetic balls for children is also one of the initiatives undertaken by ZicZic. <br/> <br/>Constructing projects using magnetic balls can help children develop spatial and logical thinking skills. We can create different structures from magnetic balls and observe their interactions based on basic physics principles like attraction and repulsion. This helps children gain a better understanding of how magnetic balls operate. <br/> <br/>By creating various shapes from magnetic balls, children can also develop problem-solving skills and spatial reasoning. We innovate, experiment, and develop new ideas rather than simply following existing patterns. Through this, children can learn to think more broadly and foster their own creativity. <br/> <br/>With ZicZic, we can encourage curiosity and creativity in children, contributing to building a future generation full of potential and passion for science and technology.'
            , thumb: '/assets/images/thumbs/bi.jpeg'
        },
        {
            id: 13,
            title: "Sculptures of characters and toys from Playdoh",
            video: '/assets/video/clay.mp4',
            url: 'play-doh-project',
            pri: 0,
            information: 'At ZicZic, we create detailed tutorial videos on how to make various shapes using Play-Doh clay, which is not just a form of simple entertainment but also an exciting creative experience for children at the age of exploration and creativity. With Play-Doh, little ones can express their artistic talents by creating unique shapes, from adorable animals to familiar objects. <br/> <br/>Playing with Play-Doh clay requires flexibility and rich imagination. Children can transform colorful clay into anything they desire, from fruits, animals to characters from fairy tales and superheroes. <br/> <br/>Moreover, Play-Doh is made from entirely natural ingredients such as flour, salt, cooking oil, etc. Additionally, Play-Doh clay does not contain any chemicals or preservatives, making it very safe for children. Little ones can freely unleash their creativity playing with Play-Doh through ZicZic\'s videos without worrying about any harm to their health.'
            , thumb: '/assets/images/thumbs/play-doh-thumb.jpeg'
        }

    ]
}

export const ServiceDB = {
    title: 'OUR CORE SERVICES',
    subTitle: 'With our experience in developing numerous successful projects, we confidently deliver comprehensive solutions to our partners.'
}

export const AboutUsDB = {
    title: 'ABOUT US',
    subTitle: 'Life is a colorful mosaic of pieces. Zic Zic is delighted to have products recognized and become a piece in the journey of every audience.'
}

export const OurTeams = {
    teams: [
        {
            name: 'Linh Bui',
            role: 'CO-Founder ZicZic',
            image: '1628529765PxhTtZ5tv8.png'
        }, {
            name: 'Hung Nguyen',
            role: 'CO-Founder ZicZic',
            image: '1628529749I993CgLrCg.png'
        }, {
            name: 'Dzung Nguyen',
            role: 'Project Management',
            image: '1664444831s97y2z2jgn.png'
        }, {
            name: 'Thuy Nguyen',
            role: 'Content Leader',
            image: '1628530259aOGSHiy7tT.png'
        }, {
            name: 'Hoa Ho',
            role: 'Editor Leader',
            image: '1705984796Ht4AcnG8LE.png'
        }, {
            name: 'Long Nguyen',
            role: 'Content Production',
            image: '1664445029iuSBY4nLzh.png'
        }, {
            name: 'Lam Nguyen',
            role: 'Content Production',
            image: '1705984951Hoj36y8RlN.png'
        }, {
            name: 'Long Hoang',
            role: 'Content Production',
            image: '1705984922ZagqyalEuV.png'
        }, {
            name: 'Que Bui',
            role: 'Content Production',
            image: '16285302208vjBPIgnab.png'
        }, {
            name: 'Diep Hoang',
            role: 'Script Writer',
            image: '1664781779XUYpWz0y5X.png'
        }, {
            name: 'Hieu Le',
            role: 'Editor',
            image: '17059846551QgUWSrxf2.png'
        }, {
            name: 'Minh Trinh',
            role: 'Editor',
            image: '1705984747NXDCQ7wtHa.png'
        }, {
            name: 'Huy Ta',
            role: 'Editor',
            image: '17059847104PiSabjOC5.png'
        }, {
            name: 'Lan Nguyen',
            role: 'Animation',
            image: '1664781827d7GHLiajzz.png'
        }
    ]
}

export const Timeline = [
    {
        date: '12/06/2016',
        title: 'Zic Zic brand was born by Linh Bui and Hung Nguyen',
    }, {
        date: '12/27/2016',
        title: 'Create Zic Zic channel on Youtube platform',
    }, {
        date: '05/26/2017',
        title: 'Released the first video on Youtube',
    }, {
        date: '06/18/2017',
        title: 'Born Zon Zon brand',
    }, {
        date: '06/20/2017',
        title: 'Released Zon Zon\'s first video on Youtube',
    }, {
        date: '06/20/2017',
        title: 'Zic Zic channel received silver play button from Youtube',
    }, {
        date: '09/05/2017',
        title: 'Zon Zon channel received silver play button from Youtube',
    }, {
        date: '04/26/2018',
        title: 'Established ZicZic Multimedia Joint Stock Company -> Producing 3D animated series for Children',
    }, {
        date: '08/11/2018',
        title: 'Zic Zic channel received gold play button from Youtube',
    }, {
        date: '02/21/2019',
        title: 'Zic Zic channel received gold play button from Youtube',
    }, {
        date: '03/22/2019',
        title: 'Established VUVU PTE LTD located at: Lucky Plaza, 304 Orchard Road # 26-04',
    }, {
        date: '12/06/2019',
        title: 'Produce 2D animated series: Fairy Tales',
    }, {
        date: '08/09/2020',
        title: 'Stop 3D, 2D projects. Cessation of ZicZic multimedia joint stock company',
    }, {
        date: '09/09/2020',
        title: '<b>Current ZicZic Studio:</b><br>Orientation throughout:<br><b>Produce content for childrend.</b><br><b>ZicZic:&nbsp;</b>7M+ subscribers on Youtube<br><b>ZonZon:</b>&nbsp;6M+ subscribers on Youtube<br><b>Youtube Channels:</b>&nbsp;50+<br><b>Facebook Fanpage:&nbsp;</b>5+',
    }
]

export const DataParters = [
    {
        name: 'google',
        image: '/assets/images/google.png'
    },
    {
        name: 'kidoo',
        image: '/assets/images/kidoo.png'
    },
    {
        name: 'youtube',
        image: '/assets/images/youtube.png'
    },
    {
        name: 'freedom',
        image: '/assets/images/freedom.png'
    },
    {
        name: 'bent pixel',
        image: '/assets/images/bent.png'
    },
]

export const titlePage = {
    home: 'Zic Zic Network',
    projects: 'Project | Zic Zic Network',
    projectDetail: 'name_project | Zic Zic Network',
    services: 'Service | Zic Zic Network',
    about: 'About | Zic Zic Network',
    contact: 'Contact | Zic Zic Network',
    ourstudio: 'Out Studio | Zic Zic Network',
    ourteam: 'Our team | Zic Zic Network',
}
// config home page
export const our_channel_title = "Zic Zic always strives to create a quality content ecosystem across various fields such as crafts, cooking, and animation.";
export const right_management = "Protect your copyrights, eliminate piracy, and earn money when others use your video or musical content within their videos. With our advanced copyright claiming technology, we've got you covered on YouTube, Facebook, Instagram and more";
export const ANALYTICS = "We help you identify the specific metrics to track in order to improve various aspects of your video business. Get actionable advice on analytics to help you improve your content strategy and understand trends that affect your overall channel health";
export const MONETIZATION = "Earn the highest rates on YouTube and monetize Facebook, TikTok and more. Benefit from our daily audits to ensure that your videos and musical assets are optimized and maximizing your profits at all times.";


export const yt_net_work_content = "We have a network with many content creators worldwide. <br/><br/> With years of experience in distributing content on YouTube, we provide partners with tools for market analysis and evaluation. <br/><br/> With the strong development of the digital content world, newcomers often face many difficulties in finding their direction. We are ready to accompany partners to help them develop the most appropriate strategy to approach the market."
export const yt_seo = "Succeeding on YouTube is by no means easy. <br/> <br/>Many individuals or organizations have spent a lot of time achieving success with their channels, and many partners have even had to stop because they couldn't compete with competitors. <br/> <br/>With powerful analytical tools and experienced experts in channel development, we will analyze your channel's data to make changes that will help your channel reach a larger audience. <br/> <br/>Additionally, we have a media team to help partners create quality profile pictures."
export const yt_social_media = "Social Media Management is the art of curating, crafting, and controlling your online identity. It's the strategic handling of your social media accounts to ensure your brand's message resonates and thrives in the digital realm. <br/> <br/>Social media management isn't just about posting content; it's about: <br/>- Fostering genuine connections with your audience. <br/>- Maintaining a unified voice and image across platforms. <br/>- Crafting content that informs, engages, and converts. <br/>- Using data to refine your approach and achieve better results. <br/> <br/>From scheduling posts to tracking performance, from engaging with your community to adapting to the latest trends, social media management is your compass in the ever-evolving landscape of the digital realm."
export const yt_marketing = "In the dynamic world of marketing, one strategy stands tall: Influencer Marketing. <br/> <br/>Influencer marketing is the art of collaborating with individuals who hold sway over a niche audience. These influencers have the power to inspire, inform, and influence the decisions of their followers. <br/> <br/>Influencer marketing isn't just a trend; it's a transformative force: <br/>- Influencers connect with their audience authentically. <br/>- Tap into new audiences through influencers' followers. <br/>- Leverage the trust influencers have built with their community. <br/>- Create content that resonates and sparks engagement. <br/> <br/>Influencer marketing is where your brand meets storytelling. It's about collaborating with individuals who share your values and vision, amplifying your message through their unique voice."
export const yt_content = "In the digital age, content is king. It's the voice of your brand, the bridge to your audience, and the storyteller of your message. But how do you turn ideas into impactful content? <br/> <br/>- Content production isn't just about churning out material; it's about creating content that: <br/>- Provides valuable information to your audience. <br/>- Sparks curiosity and entertains your viewers. <br/>- Establishes your authority and credibility. <br/>- Encourages your audience to take the next step. <br/> <br/>Content production is a journey where ideas meet execution. It's about brainstorming, planning, creating, editing, and optimizing. It's where creativity and strategy merge to form compelling narratives. <br/> <br/>From blog posts that educate to videos that entertain, from social media updates that engage to podcasts that inspire, content production is the heartbeat of your online presence."
export const contact_title = "Every day that passes is a day to improve oneself. We always listen to feedback and collaboration invitations from all friends around the world."

export const locations = [
    {
        name: 'SINGAPORE',
        address: 'Lucky Plaza, 304 Orchard Road # 26-04',
        email: 'contact@ziczic.com',
        phone: '+65 91255030',
        geo: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.784122170637!2d103.83148717674956!3d1.3045848617159597!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19926d6211eb%3A0xd76b3a1adceec494!2sLucky%20Plaza!5e0!3m2!1sen!2s!4v1712121186839!5m2!1sen!2s'
    },
    {
        name: 'CANADA',
        address: '100 Araman DR, Toronto, ON',
        email: 'contact@ziczic.ca',
        phone: '+1249 991 0199',
        geo: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2880.718210478875!2d-79.29893632269109!3d43.77870794430274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4d22c03abe799%3A0xfe51ea2189e06012!2s100%20Araman%20Dr%2C%20Scarborough%2C%20ON%20M1T%202P8%2C%20Canada!5e0!3m2!1sen!2s!4v1712121362052!5m2!1sen!2s'
    },
    {
        name: 'VIET NAM',
        address: 'Lo B3 - Ngo 217 La Thanh - Dong Da - Ha Noi',
        email: 'linhbk@ziczic.com',
        phone: '+84976226558',
        geo: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.290787774584!2d105.82176497684054!3d21.021047788040327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ab79e69351a9%3A0xcd847eb22d286a71!2zMlJDRitDUDksIE5nw7UgMjE3IMSQLiBMYSBUaMOgbmgsIENo4bujIEThu6thLCDEkOG7kW5nIMSQYSwgSMOgIE7hu5lpLCBWaWV0bmFt!5e0!3m2!1sen!2s!4v1712121400044!5m2!1sen!2s'
    }
]