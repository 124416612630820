import React from 'react';
import { Container } from 'react-bootstrap';
import Footer from '../components/Footer';
import ProjectBanner from '../components/ProjectBanner';
import HeaderPage from '../components/HeaderPage';
import { AboutUsDB, titlePage } from '../data/data';
import LandMark from '../components/LandMark';
import OurTeam from '../components/OurTeam';
import Quotes from '../components/Quotes';
import { Ourstudio } from '../components/Ourstudio';
import { Helmet } from 'react-helmet';

const About = () => {
    return (
        <div>
            <Container fluid className='p-0' style={{ overflow: 'hidden' }}>
                <Helmet>
                    <title>{titlePage.about}</title>
                </Helmet>
                <HeaderPage />
                <ProjectBanner data={AboutUsDB} />
                <br />
                <br />
                <br />
                <LandMark />
                <OurTeam />
                {/* <div style={{ padding: 60 }}>
                    <Outstudio />
                </div> */}
                <Ourstudio />
                <Quotes />
                <Footer />
            </Container>
        </div>
    );
};

export default About;