import React from 'react';
import { Container } from 'react-bootstrap';
import Footer from '../components/Footer';
import ProjectBanner from '../components/ProjectBanner';
import AllProject from '../components/AllProject';
import HeaderPage from '../components/HeaderPage';
import { ProjectDB, titlePage } from '../data/data';
import { Helmet } from 'react-helmet';

const Projects = () => {
    return (
        <div>
            <Container fluid className='p-0' style={{ overflow: 'hidden' }}>
                <Helmet>
                    <title>{titlePage.projects}</title>
                </Helmet>
                <HeaderPage />
                <ProjectBanner data={ProjectDB} />
                <AllProject tags={ProjectDB.project_tags} />
                <Footer />
            </Container>
        </div>
    );
};

export default Projects;