import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
const ServicesEnd = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [content, setContent] = useState('');
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState('SEND')

    const handleShow = () => {
        setTitle('SENDING...')
        setTimeout(() => {
            setShow(true);
            setTitle('SEND')
        }, 2000);

    }
    const handleClose = () => setShow(false);

    return (
        <Row className='row-1500 full-width' style={{ padding: 10 }}>
            <Col xl={6} xs={12}>
                <div className='right-img'>
                    <div className='red-background'>
                        <img src="/assets/images/services-contact.png" alt="youtube network" style={{ width: '100%' }} />
                    </div>
                </div>
            </Col>
            <Col xl={6} xs={12}>
                <p className='montserrat sub-title-feature mobile-center' style={{ color: 'red', marginTop: 20 }}>WE WOULD LOVE <br />TO HEAR FROM YOU!</p>
                <div className='form-contacts'>
                    <Form.Control size="lg" type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} required />
                    <Form.Control.Feedback type="invalid">
                        Please input correct name.
                    </Form.Control.Feedback>
                    <br />
                    <Form.Control size="lg" type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    <Form.Control.Feedback type="invalid">
                        Please input correct Email.
                    </Form.Control.Feedback>
                    <br />
                    <Form.Control as="textarea" rows={7} placeholder="Content" value={content} onChange={(e) => setContent(e.target.value)} required />
                    <Form.Control.Feedback type="invalid">
                        Please input correct content.
                    </Form.Control.Feedback>
                    <div className='flex-center '>
                        <button className='button-banner' style={{ marginTop: 20 }} onClick={handleShow}>{title}</button>
                    </div>
                </div>
                <Modal show={show} animation={true}>
                    <Modal.Header>
                        <Modal.Title><p className='note-txt'>Information</p></Modal.Title>
                    </Modal.Header>
                    <Modal.Body><p className='note-txt'>Thank you for submitting your request. We will respond to you shortly.</p></Modal.Body>
                    <Modal.Footer>
                        <button className='button-banner' onClick={handleClose}>Close</button>
                    </Modal.Footer>
                </Modal>
            </Col>
        </Row >
    );
};

export default ServicesEnd;