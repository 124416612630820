import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { PlayFill } from 'react-bootstrap-icons';
import { ProjectDB } from '../data/data';

const Feature = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 767);

    useEffect(() => {
        function handleResize() {
            setIsSmallScreen(window.innerWidth < 767);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='flex-center full-width padding-mobile'>
            <Row className='row-1920'>
                <Row>
                    <Col className='our-channels' style={{ backgroundColor: '#fff', textAlign: 'center' }}>
                        <p className='title-txt'>FEATURED WORK</p>
                    </Col>
                </Row>
                {
                    ProjectDB.projects.filter(item => item.pri !== 0).slice(0, 3).sort((a, b) => a.pri - b.pri).map((item, index) => (
                        <Row style={{ display: 'flex', flexDirection: `${index % 2 === 0 ? 'row-reverse' : 'row'}` }} key={index}>
                            <Col md={6} xs={12} style={{ padding: 0 }}>
                                <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                                    <video className='feature-video' autoPlay loop muted playsInline>
                                        <source src={item.video} type='video/mp4' />
                                    </video>
                                </div>
                            </Col>
                            <Col md={6} xs={12} style={{ display: 'flex', alignItems: 'center', backgroundColor: `${(index % 2 === 0 || isSmallScreen) ? '#D9D9D9' : '#FF0101'}`, padding: 0 }}>
                                <div className={`feature-title ${index % 2 !== 0 && 'p-left-30'}`}>
                                    <div style={{ width: '100%' }}>
                                        <p className={`montserrat ${index % 2 === 0 ? 'text-md-end' : 'text-md-start'} text-center sub-title-feature`}
                                            style={{ textTransform: 'uppercase', flex: 1, color: `${(index % 2 === 0 || isSmallScreen) ? '#000' : '#fff'}` }}
                                            dangerouslySetInnerHTML={{
                                                __html: item.title,
                                            }}></p>
                                    </div>
                                    <div className={`${index % 2 === 0 ? 'text-md-end' : 'text-md-start'} text-center`} style={{ width: '100%' }}>
                                        <a href={`/project/${item.url}`} className={`${(index % 2 === 0 || isSmallScreen) ? 'button-banner' : 'button-banner-second'}`} >View project</a>
                                    </div>
                                </div>
                            </Col>
                        </Row >
                    ))
                }
                {/* <Row style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <Col md={6} xs={12} style={{ padding: 0 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                            <video className='feature-video' autoPlay loop muted playsInline>
                                <source src="/assets/video/acdda38-ten-little-duck.mp4" type='video/mp4' />
                            </video>
                        </div>
                    </Col>
                    <Col md={6} xs={12} style={{ display: 'flex', alignItems: 'center', backgroundColor: '#D9D9D9', padding: 0 }}>
                        <div className='feature-title'>
                            <div style={{ width: '100%' }}>
                                <p className='montserrat text-md-end text-center sub-title-feature'>TEN LITTLE DUCKIES - 3D <br /> ANIMATION PROJECT </p>
                            </div>
                            <div className='text-md-end text-center' style={{ width: '100%' }}>
                                <a href="/project/ten-little-duckies-3d-animation-project" className='button-banner'>View project</a>
                            </div>
                        </div>
                    </Col>
                </Row >
                <Row style={{ display: 'flex' }}>
                    <Col md={6} xs={12} style={{ padding: 0 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                            <video className='feature-video' autoPlay loop muted playsInline>
                                <source src="/assets/video/acde223-1.mp4" type='video/mp4' />
                            </video>
                        </div>
                    </Col>
                    <Col md={6} xs={12} style={{ display: 'flex', alignItems: 'center', backgroundColor: '#FF0101', padding: 0 }}>
                        <div className='feature-title p-left-30'>
                            <div style={{ width: '100%' }}>
                                <p className='montserrat text-md-start text-center sub-title-feature' style={{ color: '#fff' }}>THE TINY HOUSE UNDER <br /> THE MOONLIGHT - 3D <br /> ANIMATION PROJECT</p>
                            </div>
                            <div className='text-md-start text-center' style={{ width: '100%', marginTop: 20 }}>
                                <a href="/project/the-tiny-house-under-the-moonlight-3d-animation-project" className='button-banner-second'>View project</a>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <Col md={6} xs={12} style={{ padding: 0 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                            <video className='feature-video' autoPlay loop muted playsInline>
                                <source src="/assets/video/acd54b2-splitting-hares.mp4" type='video/mp4' />
                            </video>
                        </div>
                    </Col>
                    <Col md={6} xs={12} style={{ display: 'flex', alignItems: 'center', backgroundColor: '#D9D9D9', padding: 0 }}>
                        <div className='feature-title'>
                            <div style={{ width: '100%', textAlign: 'right' }}>
                                <p className='montserrat montserrat text-md-end text-center sub-title-feature'>SPLITTING HARES - PENCILANIMATION <br /> PROJECT</p>
                            </div>
                            <div className='montserrat text-md-end text-center' style={{ width: '100%', marginTop: 20 }}>
                                <a href="/project/splitting-hares-pencilanimation-project" className='button-banner'>View project</a>
                            </div>
                        </div>
                    </Col>
                </Row> */}
                <Row>
                    <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#D9D9D9', padding: 0 }}>
                        <a href="/projects" className='montserrat see-work-btn'><span>SEE ALL OUR WORK</span><PlayFill size={36} className='play-icon' /></a>
                    </Col>
                </Row>
            </Row>
        </div >
    );
};

export default Feature;