import React from 'react';
import { Container } from 'react-bootstrap';
import Footer from '../components/Footer';
import HeaderPage from '../components/HeaderPage';
import OurStudioCard from '../components/OurStudioCard';
import OurStudioImageStack from '../components/OurStudioImageStack';
import { Helmet } from 'react-helmet';
import { titlePage } from '../data/data';

const OurStudio = () => {
    return (
        <div>
            <Container fluid className='p-0' style={{ overflow: 'hidden' }}>
                <Helmet>
                    <title>{titlePage.ourstudio}</title>
                </Helmet>
                <HeaderPage />
                <br />
                <OurStudioCard />
                <br />
                <br />
                <OurStudioImageStack />
                <br />
                <Footer />
            </Container>
        </div>
    );
};

export default OurStudio;