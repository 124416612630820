import React from 'react';
import { Container } from 'react-bootstrap';
import Footer from '../components/Footer';
import ProjectBanner from '../components/ProjectBanner';
import HeaderPage from '../components/HeaderPage';
import { ServiceDB, titlePage } from '../data/data';
import ListItems from '../components/ListItems';
import ServicesEnd from '../components/ServicesEnd';
import { Helmet } from 'react-helmet';
import Recaptcha from '../components/Recaptcha';

const Services = () => {
    return (
        <div style={{ position: 'relative' }}>
            <Container fluid className='p-0' style={{ overflow: 'hidden' }}>
                <Helmet>
                    <title>{titlePage.services}</title>
                </Helmet>
                <HeaderPage />
                <ProjectBanner data={ServiceDB} />
                <br />
                <br />
                <br />
                <ListItems />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#EBEBEB', padding: '60px 0' }}>
                    <ServicesEnd />
                </div>
                <Footer />
            </Container>
            <Recaptcha />
        </div>
    );
};

export default Services;