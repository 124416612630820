import React from 'react';
import { Col, Row } from 'react-bootstrap';

const ProjectBanner = ({ data }) => {
    return (
        <Row>
            <Col>
                <div className='project-banner flex-center' style={{ width: '100%'}}>
                    <img src={data.image ? data.image : '/assets/images/project-banner.png'} alt='Projects' />
                    <div className='project-banner-overlay'></div>
                    <div className='project-banner-text'>
                        <p className='title-txt'>{data.title}</p>
                        <p className='note-txt' style={{ marginBottom: -4, marginTop: -20 }} dangerouslySetInnerHTML={{
                            __html: data.subTitle,
                        }}></p>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default ProjectBanner;