import React from 'react';
import { Col, Row } from 'react-bootstrap';

export function Partnership() {
    return (
        <Row style={{ backgroundColor: '#ebebeb' }} className='flex-center'>
            <div className='partner-ship-contain'>
                <Col md={6} xs={12}>
                    <div className='partner-left'>
                        <div className='partner-ship'>
                            <p className='montserrat text-md-start text-center' style={{ fontWeight: 700 }}>PARTNERSHIP</p>
                            <p className='montserrat-regular text-md-start text-center note-txt' style={{ fontWeight: 100, marginTop: -10 }}>ZIC ZIC is always looking for new <br /> partners around the world</p>
                            <p className='montserrat text-md-start text-center'><a href='/contact' className='button-banner'>Contact Us</a></p>

                        </div>
                    </div>
                </Col>
                <Col md={6} xs={12} className='flex-center'>
                    <div className='partner-right'>
                        <div className='image-with-bg'>
                            <img src='/assets/images/partner.png' alt='partners' />
                        </div>
                    </div>
                </Col>
            </div>
        </Row>
    );
}
