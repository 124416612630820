import React from 'react';
import { Col, Row } from 'react-bootstrap';

export function Ourstudio() {
    return (
        <Row className='flex-center'>
            <div className='partner-ship-contain flex-column-mobile'>
                <Col md={7} xs={12} className='flex-center'>
                    <div className='partner-right no-padding-right'>
                        <div className='image-with-bg'>
                            <img src='/assets/images/studio.png' alt='out studio' />
                        </div>
                    </div>
                </Col>
                <Col md={5} xs={12}>
                    <div className='partner-left no-margin-left'>
                        <div className='partner-ship'>
                            <p className='montserrat text-md-start text-center' style={{ fontWeight: 700 }}>OUR STUDIO</p>
                            <p className='montserrat-regular text-md-start text-center note-txt' style={{ fontWeight: 100, marginTop: -10 }}>To make a product is both an effort and <br/> creativity of the content production team.<br/> Let's take a look at our work.</p>
                            <p className='montserrat text-md-start text-center'><a href='/our-studio' className='button-banner'>View our studio</a></p>
                        </div>
                    </div>
                </Col>
            </div>
        </Row>);
}
