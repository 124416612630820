import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ProjectDB } from '../data/data';
import ProjectCard from './ProjectCard';

function shuffleArray(array) {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
}


const ProjectSlide = ({ data }) => {
    return (
        <Row className='row-1500'>
            {
                shuffleArray(ProjectDB.projects.filter(project => project.url !== data)).slice(0, 3).map((item, index) => (
                    <Col xxl={4} xl={6} md={6} xs={12} key={index}>
                        <ProjectCard type={index === 0 ? 0 : 1} data={item} index={index} key={index} />
                    </Col>
                ))
            }
        </Row>
    );
};

export default ProjectSlide;