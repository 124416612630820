import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Banner from '../components/Banner';
import OurChannel from '../components/OurChannel';
import Feature from '../components/Feature';
import { Container } from 'react-bootstrap';
import Partners from '../components/Partners';
import Footer from '../components/Footer';
import { Partnership } from '../components/Partnership';
import HeaderPage from '../components/HeaderPage';

const Home = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 767);

    useEffect(() => {
        function handleResize() {
            setIsSmallScreen(window.innerWidth < 767);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            <Container fluid className='p-0' style={{ overflow: 'hidden' }}>
                {isSmallScreen ? <HeaderPage /> : <Header />}
                <Banner />
                <OurChannel />
                <Feature />
                <Partners />
                <Partnership />
                <Footer />
            </Container>
        </div>
    );
};

export default Home;